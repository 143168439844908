import React from "react";
import SecondaryHeader from "./secondary-header";

const Error = () => (
    <div>
        <SecondaryHeader/>

        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: '10% 0'}}>
            <h6>404</h6>
            <h3 style={{fontSize: '160%'}}>Page Not Found</h3>
        </div>
    </div>
);

export default Error