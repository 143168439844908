import Layout from "./404";
import React from "react";
import SEO from "../components/seo"
import Error from "../components/error";

const ErrorPage = () => (
    <Layout>
        <SEO title="404: Not found" />
        <Error/>
    </Layout>
);

export default ErrorPage